import React, { useRef, useState } from 'react';
import './App.css';

import test2 from "./images/test.mp4"; // Main video
import loaderVideo from "./images/test2.mp4"; // Loader video

import Navbar from './Navbar';

function App() {
  const videoRef = useRef(null);
  const [showMainContent, setShowMainContent] = useState(false); // Track when to show the main content
  const [isMuted, setIsMuted] = useState(true);

  const handleLoaderEnd = () => {
    setShowMainContent(true); // Show the main content when loader ends
  };

  const toggleMute = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <div className="App">
      {!showMainContent ? (
        <div className="loader">
          <video
            src={loaderVideo}
            autoPlay
            muted
            style={{
              objectFit: 'cover',
              height: '100vh',
              width: '100%',
            }}
            onEnded={handleLoaderEnd} // Transition when loader video finishes
          />
        </div>
      ) : (
        <>
          <Navbar />
          <div className="video">
            <video
              ref={videoRef}
              src={test2}
              width="100%"
              style={{ marginBottom: '-5px' }}
              autoPlay
              loop
              muted={isMuted}
            />
            <button
              onClick={toggleMute}
              className="futuristic-button"
            >
              {isMuted ? 'Unmute' : 'Mute'}
            </button>
          </div>

          {/* Blurry text container */}
          <div className="blurry-text-container">
            <div className="blurry-text">
              Chatbot coming soon
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
