// src/Navbar.js
import React, { useState } from 'react';
import './Navbar.css';

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false); // State for mobile menu toggle

  const toggleMobileMenu = () => {
    setIsMobile(!isMobile);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">MINDSTREAM AI</div>
      <ul className={`navbar-links ${isMobile ? 'active' : ''}`}>
        <li>
          <a
            href="https://x.com/AIMINDSTREAM"
            target="_blank"
            className="navbar-link"
          >
            <span className="navbar-twitter-text">Twitter</span>
          </a>
        </li>
        {/* Add more links and icons here if needed */}
      </ul>
      <div className="navbar-toggle" onClick={toggleMobileMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
  );
};

export default Navbar;
